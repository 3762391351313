import "./style.scss"

var smoothScroll = function (range) {
    var position = range; // スクロールする位置
    var progress = 0; // 現在の進捗 0 ～ 100
    var easeOut = function (p) { // ease-out に当てはめた値を返す
        return p * (2 - p);
    };
    var move = function () { // 実際にスクロールを行う
        progress++; // 進捗を進める
        position -= range * easeOut(progress / 300); // スクロールする位置を計算する

        window.scrollTo(0, position); // スクロールさせる
        if (position < 0) {
            return
        }

        if (position < range) { // 現在位置が目的位置より進んでいなければアニメーションを続行させる
            requestAnimationFrame(move);
        }
    };

    requestAnimationFrame(move); // 初回呼び出し
};


window.onload = () => {
    const scrollTopButton = document.getElementById("scrollTop")
    if (scrollTopButton) {
        if (!window.onscroll) {
            window.onscroll = () => {
                if (document.getElementById("contact_toppage_start_bg").getBoundingClientRect().top < 1000) {
                    scrollTopButton.classList.remove("hide")
                } else  {
                    scrollTopButton.classList.add("hide")
                }
            }
        }
        scrollTopButton.addEventListener("click", () => {
            smoothScroll(window.scrollY)
        })
    }
}